<template>
  <div class="coupons-list">
    <el-form ref="ruleFormRef" label-position="left" :model="ruleForm"
    class="demo-ruleForm" label-suffix="：">
        <el-form-item prop="shopId" label="授权点评门店">
            <el-select v-model="ruleForm.shopId" class="m-2" placeholder="选择门店" @change="getServeData">
                <el-option v-for="item in shopList" :key="item.shopName" :label="item.shopName" :value="item.shopId" />
            </el-select>
        </el-form-item>
    </el-form>
    <el-table :data="getShopServeList" style="width: 100%">
      <el-table-column align="center" type="index" label="序号" />
      <el-table-column align="center" prop="tripartiteServeId" label="美团点评商品ID" />
      <el-table-column align="center" prop="tripartiteServeName" label="美团点评商品名称" />
      <el-table-column align="center" prop="price" label="美团点评商品价格" />
      <el-table-column prop="serveId" label="指挥官商品" width="250" align="center">
          <template #default="scope">
              <el-input v-model="scope.row.serveName" placeholder="请选择服务" readonly/>
          </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="核销门店">
        <template #default="scope">
          <el-link type="primary" @click="openShopList(scope.row)">{{scope.row.shopNameList&&scope.row.shopNameList.length}}</el-link>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="绑定时间" />
      <el-table-column prop="date" label="操作" align="center" width="200px">
        <template #default="scope">
          <el-button type="text" size="small"  @click="openDiaolog(scope.$index,scope.row)" v-if="!scope.row.bindId">绑定</el-button>
          <el-button type="text" size="small" v-else style="color: red;" @click="handleRemoveClick(scope.row.bindId)">解绑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="dialogVisible" width="660px" @close="closeDialogVisible">
        <div class="reserve-project-list">
            <div class="reserve-top-form">
                <el-input
                    class="reserve-el-select"
                    @input="handleSearch"
                    size="small"
                    prefix-icon="search"
                    v-model="searchValue"
                    placeholder="输入名称"
                />
            </div>
            <div class="serve_flex">
                <div class="category-list">
                    <template :key="item" v-for="(item, index) in teacherServeList">
                        <div @click="handleTypeClick(item, index)" :class="{ active: optServeId == item.kindId }" class="item">
                            {{ item.kindName }}
                        </div>
                    </template>
                </div>
                <div class="shop-list">
                    <div class="titls">
                        <div style="width: 230px;">服务</div>
                        <div>价格</div>
                        <div>时长</div>
                        <div style="width: 80px;">勾选</div>
                    </div>
                    <el-scrollbar height="240px" width="520px">
                        <div class="serve-list" v-if="teacherServeInfoList&&teacherServeInfoList.length">
                            <template :key="item" v-for="item in teacherServeInfoList">
                                <div @click="handleServeClick(item)" class="item">
                                    <div class="left">
                                        <div class="name">{{ item.serveName }}</div>
                                    </div>
                                    <div class="right">
                                        <div class="checkbox">
                                            <div class="salePrice">¥{{ item.price }}</div>
                                            <div>{{ item.serveLength }}分钟</div>
                                        </div>
                                    </div>
                                    <div>
                                      <div class="icon" style="width:80px" v-if="item.serveId==serveInfo.serveId">
                                          <!-- <el-icon color="#7948ea"><CircleCheckFilled /></el-icon> -->
                                          <el-icon color="#7948ea"><SuccessFilled /></el-icon>
                                      </div>
                                      <div class="icon" v-else>
                                        〇
                                      </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </el-dialog>
    <bindShopDialog
      v-if="dialogVisibleShop"
      :dialogVisible="dialogVisibleShop"
      @changeDialogVisible="changeDialogVisible"
      :shopNameList="shopNameList"
    ></bindShopDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { useRouter } from 'vue-router'
import bindShopDialog from './cpns/bindshop-dialog/index.vue'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import { getShopList } from '@/service/main/target'
import { getTripartiteServeList , bindServe ,signBindServe,unbindServe} from "@/service/main/serve";
import { getTeacherRankServeList } from "@/service/main/reserve";

export default defineComponent({
  props: {
    activeName: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageContent,
    bindShopDialog
  },
  setup(props) {
    const router = useRouter()
    const dialogVisibleShop = ref(false)
    const shopNameList = ref([])

    const shopList = ref([])
    const getShopServeList = ref([])
    const teacherServeInfoList = ref([])
    const ruleForm = reactive({
      shopId: ''
    })
    const searchValue = ref('')
    const tableIndex = ref('')
    const teacherServeList = ref([])
    const optServeId = ref('')
    const serveInfo = reactive({
        serveName:'',
        serveId:''
    })
    const handleSearch = value => {
        let list = teacherServeList.value.filter(item=>{return item.kindId==optServeId.value})[0].infos
        let newList = []
        // console.log(value)
        // console.log(value.trim().length > 0);
        if (value.trim().length > 0) {
            list.forEach(ele => {
            if (ele.serveName.indexOf(value) != -1) {
                newList.push(ele)
            }
            })
        } else {
            newList = list
        }
        teacherServeInfoList.value = newList
    }
    // const tripartiteShopId = ref(null)
    const openShopList = (item) =>{
      dialogVisibleShop.value=true
      shopNameList.value=item.shopNameList.map(item=>{
        return {
          name:item
        }
      })
      console.log(shopNameList.value);
    }
    //删除
    const handleRemoveClick = (id) => {
      popup('确认解绑当前服务吗?', async() => {
        let res = await unbindServe(id)
        if (res.code==0) {
          ElMessage.success({
            message: '解绑成功!',
            type: 'success'
          })
          refresh()
        }else{
          ElMessage.error({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
    const handlebindClick = async (item) => {
      let serveId = item.serveId
      let tripartiteServeId = item.tripartiteServeId
      let data = await signBindServe({serveId,tripartiteServeId,shopId:ruleForm.shopId,type:1})
      if (data.code==0) {
          ElMessage.success({
            message: '绑定成功!',
            type: 'success'
          })
          refresh()
        }else{
          ElMessage.error({
            message: data.msg,
            type: 'error'
          })
        }
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisibleShop.value = flag
    }
    const refresh = () => {
      getServeData(ruleForm.shopId)
    }
    const openDiaolog =(e,item) => {
        tableIndex.value=e
        if (item.serveId) {
            serveInfo.serveName=item.serveName
            serveInfo.serveId=item.serveId 
            optServeId.value=deWeight(teacherServeList.value,item.serveId)[0].kindId||1
            teacherServeInfoList.value=teacherServeList.value.filter(item=>{return item.kindId==optServeId.value})[0].infos
        }
        dialogVisible.value=true
    }
    const deWeight = (arr,serveId) =>{
        let arr1 = [];
        arr.map(item=>{
          item.infos.map(items=>{
            if (items.serveId==serveId) {
              arr1.push(items)
            }
          })
        })
        return arr1;
    }

    const handleTypeClick = (item, index) => {
        // console.log(item, index);
        let value = item.kindId;
        optServeId.value = value;
        let list = teacherServeList.value
        // console.log(list,item);
        // console.log(String(value.length > 0));

        if (String(value).length > 0) {
            list = list.filter(ele => ele.kindId == value)
            // console.log(123123);
        }
        // console.log(list);
        teacherServeInfoList.value = list[0].infos
    }
    const closeDialogVisible = () => {
        dialogVisible.value=false
        serveInfo.serveId=''
        tableIndex.value=''
        serveInfo.serveName=''
        optServeId.value=teacherServeList.value[0].kindId;
        teacherServeInfoList.value=teacherServeList.value[0].infos
    }
    const handleServeClick = (item) => {
        getShopServeList.value[tableIndex.value].serveId=item.serveId
        getShopServeList.value[tableIndex.value].serveName=item.serveName
        serveInfo.serveName=item.serveName
        serveInfo.serveId=item.serveId
        dialogVisible.value=false
        let params = getShopServeList.value[tableIndex.value]
        handlebindClick(params)
    }
    const getServeCatewgory = async () => {
        const res = await getTeacherRankServeList({})
        res.data.map(item=>{
          item.infos.map(items=>{
            items.kindId=item.kindId
          })
        })
        teacherServeList.value=res.data
        optServeId.value = teacherServeList.value[0].kindId;
        teacherServeInfoList.value=teacherServeList.value[0].infos
    }
    const getServeData = async(e) => {
        let data = await getTripartiteServeList({shopId:e,type:1})
        getShopServeList.value = data.data
    }
    const initPage = async () => {
        const res = await getShopList(10000)
        shopList.value = res.data.list
        ruleForm.shopId = res.data.list[0].shopId
        getServeData(res.data.list[0].shopId)
        getServeCatewgory()
    }
    initPage()

    return {
      dialogVisible,
      changeDialogVisible,
      handlebindClick,
      handleRemoveClick,
      shopList,
      // tripartiteShopId,
      refresh,
      ruleForm,
      getServeData,
      getShopServeList,
      openDiaolog,
      tableIndex,
      teacherServeList,
      teacherServeInfoList,
      serveInfo,
      handleTypeClick,
      optServeId,
      handleServeClick,
      closeDialogVisible,
      openShopList,
      dialogVisibleShop,
      shopNameList,
      searchValue,
      handleSearch
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  // margin-top: 20px;
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
  &:deep(.el-table) {
    // font-size: 12px;
    thead.is-group th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
  }
  .reserve-project-list {
      .serve_flex{
          display: flex;
      }
      .active {
          background-color: #fff !important;
          color: rgb(133, 88, 250);
          font-weight: 550;
      }
      .category-list {
          width: 100px;

          .item {
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          line-height: 45px;
          background-color: rgb(239, 239, 239);
          border-bottom: 1px solid rgb(255, 255, 255);
          min-width: 100px;
          cursor: pointer;
          }
      }

      .reserve-top-form {
          display: flex;
          justify-content: space-between;
          background-color: rgb(239, 239, 239);
          padding: 10px 20px;

          .reserve-el-select {
          width: 45%;
          }
      }

      &:deep(.serve-list-wrap) {
          padding-right: 10 !important;
      }

      .empty {
          width: 100%;
          display: flex;
          justify-content: center;
      }
  }
  .shop-list {
    width: 520px;
    text-align: center;
    .serve-list {
      .item {
        display: flex;
        height: 40px;
        align-items: center;
        border-bottom: 1px solid #efefef;
        .icon{
            width: 80px;
            display: flex;
            justify-content: center;
            color: #ccc;
          }

        .left {
        //   width: 330px;
          display: flex;
          height: 40px;
          align-items: center;
          .name{
            width: 230px;
          }
        }

        .right {

          .checkbox {
            display: flex;

            div {
                width: 110px;
            }

            .salePrice {
              color: rgb(255, 105, 74);
            }
          }
        }
      }
    }

    .titls {
      display: flex;
    //   margin-bottom: 20px;

      div {
        width: 110px;
        line-height: 40px;
        background-color: rgb(239, 239, 239);
      }
    }

  }
}
</style>
