<template>
  <div class="extension-dialog">
    <pageDialog
      title="核销门店"
      width="500px"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <div class="list-style">
        <el-table :data="shopNameList" style="width: 100%;" height="450">
          <el-table-column align="center" prop="name" label="核销门店" />
        </el-table>
      </div>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,nextTick } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { TUI_GUANG_BAC_IMG, TUI_GUANG_BAC_IMG2 } from '@/common/constant'
import { ElMessage } from 'element-plus'
import { setTuiguangImg } from '@/service/main/base'
import { bindShop } from '@/service/main/shop'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    shopNameList: {
      type: Array,
      default() {
        return []
      }
    },
    tripartiteShopId: {
      type:String,
      default: null
    }
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible','refresh'],
  setup(props, { emit }) {

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }

    return {
      changeDialogVisible,
      TUI_GUANG_BAC_IMG,
      TUI_GUANG_BAC_IMG2
    }

  }
})
</script>

<style scoped lang="less">
.extension-dialog {
  .btn_flex{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
}
</style>
