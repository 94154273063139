<template>
  <div class="coupons-list">
    <div class="top-content">
      <div class="left">
        <el-button class="add-staff" type="primary" @click="handleAddClick">授权点评门店</el-button>
      </div>
      <div class="right">
        <p>授权时间：{{lastTime}}</p>
      </div>

    </div>
    <el-table :data="dataList" style="width: 100%">
      <el-table-column align="center" prop="tripartiteName" label="点评门店名称" />
      <el-table-column align="center" prop="name" label="指挥官门店名称" />
      <el-table-column align="center" prop="bindTime" label="绑定时间" />
      <el-table-column prop="date" label="操作" align="center" width="250px">
        <template #default="scope">
          <el-button type="text" v-if="!scope.row.mpId" size="small" @click="handlebindClick(scope.row.tripartiteShopId)">绑定</el-button>
          <el-button type="text" size="small" style="color: red;" @click="handleRemoveClick(scope.row.mpId)" v-else>解绑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <bindShopDialog
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      :shopList="shopList"
      :tripartiteShopId="tripartiteShopId"
      @refresh="refresh"
    ></bindShopDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { getMeiTuanShopList , getBingShopList , unBindShop ,getLastAuthTime } from '@/service/main/shop'
import { useRouter } from 'vue-router'
import bindShopDialog from './cpns/bindshop-dialog/index.vue'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    activeName: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageContent,
    bindShopDialog
  },
  setup(props) {
    const router = useRouter()

    const dataList = ref([])
    const shopList = ref([])
    const tripartiteShopId = ref(null)
    const lastTime = ref('')
    const handleAddClick = () => {
      router.push({
        path: '/main/shop/empower'
      })
    }
    //删除
    const handleRemoveClick = (shopId) => {
      popup('确认解绑当前门店吗?', async() => {
        let res = await unBindShop(shopId)
        if (res.code==0) {
          ElMessage.success({
            message: '解绑成功!',
            type: 'success'
          })
          initPage()
        }else{
          ElMessage.error({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }

    //编辑
    const handlebindClick = async (id) => {
      tripartiteShopId.value = id 
      let data = await getBingShopList({type:1})
      shopList.value = data.data
      changeDialogVisible(true)

    }

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
      if (!flag) {
        tripartiteShopId.value=null
        shopList.value = []
      }
    }
    const refresh = flag => {
      dialogVisible.value = flag
      tripartiteShopId.value=null
      shopList.value = []
      initPage()
    }

    const initPage = async () => {
      const res = await getMeiTuanShopList()
      const resTime = await getLastAuthTime()
      let list = res.data
      dataList.value = list
      lastTime.value = resTime.data
    }
    initPage()

    return {
      dialogVisible,
      changeDialogVisible,
      dataList,
      handlebindClick,
      handleRemoveClick,
      shopList,
      tripartiteShopId,
      refresh,
      lastTime,
      handleAddClick
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .left {}

    .right {
      display: flex;

      .search-wrap {
        margin-left: 10px;
      }
    }

  }
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
  &:deep(.el-table) {
    // font-size: 12px;
    thead.is-group th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
  }
}
</style>
